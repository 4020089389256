import { createGlobalStyle } from 'styled-components';

const TransactionViewModeStyles = createGlobalStyle`
  input, [class^="Button-module_btn"], [class^="FieldFrame-module_fieldContainer"] {
    ${({ disabled }) =>
      disabled &&
      `
        cursor: text;
      `}
  }
  
  [class^="Button-module_btn"] {
    ${({ disabled }) =>
      disabled &&
      `
        pointer-events: none;
        cursor: text;
      `}
  }
      

  [class^="Button-module_btn"], [class^="LoadingListTableRow-module_deleteIcon"],
  [class^="ContainerItemsTableRow-module_deleteIcon"],
  &.delete-vehicle, [class^="VehicleFormPreview-module_deleteIcon"] {
    ${({ disabled }) =>
      disabled &&
      `
        display: none;
      `}
  }
      @media (max-width: 1200px) {
        div[class^="Row-module_row"] {
          align-items: flex-end !important;
        }
        div[class^="Row-module_row"] span {        
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%
        }
      }

  [class^="FieldFrame-module_fieldContainer"] {
    ${({ disabled }) =>
      disabled &&
      `
        // background-color: #FAFBFC !important;
      `}
  }

  #transactionClone, #exportXml, [class^="Pagination-module_buttonsSection"] > button,
  [class^="ToastrManager-module_actions"] > button {
    display: inline-flex !important;
    pointer-events: all;
    cursor: pointer;
  }

  [class^="AdminActions-module_btnContainer"] > button {
    display: inline-flex !important;
    pointer-events: all;
    cursor: pointer;
  }
  
  [class^="ItemsModal-module_pagination"] > [class^="Pagination-module_pagination"],
  [class^="VehicleImportModal-module"] > div > [class^="Pagination-module_pagination"],
  [class^="ItemsTab-module_pagination"] > [class^="Pagination-module_pagination"] {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }

  [class^="ItemsModal-module_pagination"] > [class^="Pagination-module_pagination"] > [class^="Pagination-module_jumpToPageContainer"],
  [class^="VehicleImportModal-module"] > div > [class^="Pagination-module_pagination"] > [class^="Pagination-module_jumpToPageContainer"],
  [class^="ItemsTab-module_pagination"] > [class^="Pagination-module_pagination"] > [class^="Pagination-module_jumpToPageContainer"] {
      margin: 3px 0;
  }
`;

export default TransactionViewModeStyles;
